import React from 'react';

import SectionBlock from '../components/Block/SectionBlock';

const sections = [
  {
    img: false,
    text: [
      {
        title: 'Customer',
        description:
          '<p>BedreKredit.dk is Danish fintech company offering credit ' +
          'arrangements - quick loans, mini loans, etc. to consumers.</p>',
      },
      {
        title: 'Challenge',
        description:
          '<ul>' +
          '<li>Development of new client-site application features:' +
          '<ul>' +
          '<li>Extending the application to combat fraud and streamline creditworthiness checks</li>' +
          '<li>Active safeguarding of sensitive data, both in transit and at rest</li>' +
          '<li>Collecting and processing data from various sources</li>' +
          '</ul></li>' +
          '<li>Meeting legal requirements related to personal data protection</li>' +
          '<li>Finding a mature and skilled team of SW engineers able to start the project immediately</li>' +
          '</ul>',
      },
      {
        title: 'Solution',
        description:
          '<ul>' +
          '<li>SolveQ team responsibility:' +
          '<ul>' +
          '<li>Architecture design solving privacy and security issues</li>' +
          '<li>Client-site application development</li>' +
          '</ul></li>' +
          '<li>Agile methodologies</li>' +
          '</ul>',
      },
      {
        title: 'Results ',
        description:
          '<ul>' +
          '<li>Customers can easily check their creditworthiness based on Danish Tax Office (SKAT)  data and other sources of information</li>' +
          '<li>Application meeting legal requirements</li>' +
          '<li>Personal data protection</li>' +
          '<li>Secure data exchange keeping sensitive information safe</li>' +
          '</ul>',
      },
      {
        title: 'Technologies ',
        description:
          '<ul>' +
          '<li>Infrastructure:' +
          '<ul>' +
          '<li>Amazon Web Services</li>' +
          '<li>Bitbucket</li>' +
          '<li>Jira</li>' +
          '<li>Auth0</li>' +
          '</ul></li>' +
          '<li>Application backend:' +
          '<ul>' +
          '<li>PHP 7.x</li>' +
          '<li>Symfony</li>' +
          '<li>Wordpress</li>' +
          '<li>Mambu</li>' +
          '<li>Sodium</li>' +
          '</ul></li>' +
          '<li>Application client:' +
          '<ul>' +
          '<li>Nuxt.js</li>' +
          '<li>Vue.js</li>' +
          '<li>Sodium</li>' +
          '</ul></li>' +
          '</ul>',
      },
    ],
  },
];

const BedrekreditPage = () => {
  return (
    <>
      <div className="th-hero-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="th-hero-content">
                <h1 className="th-hero-content__header">Vue Nuxt Web Application</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SectionBlock imgFull imgFirst {...sections[0]} />
    </>
  );
};

export default BedrekreditPage;
